export interface RankPayload {
    distance: number
    distance_unit: string
    rank: number
    user_id: number
    user_first_name: string
    user_last_name: string
    user_photo_url: string
}

export class Rank {

    distance: number
    distanceUnit: string
    rank: number
    userId: number
    userFirstName: string
    userLastName: string
    userPhotoUrl: string

    constructor(data: RankPayload) {
        this.distance = data.distance
        this.distanceUnit = data.distance_unit
        this.rank = data.rank
        this.userId = data.user_id
        this.userFirstName = data.user_first_name
        this.userLastName = data.user_last_name
        this.userPhotoUrl = data.user_photo_url
    }

    fill(data: RankPayload): this {
        this.distance = data.distance
        this.distanceUnit = data.distance_unit
        this.rank = data.rank
        this.userId = data.user_id
        this.userFirstName = data.user_first_name
        this.userLastName = data.user_last_name
        this.userPhotoUrl = data.user_photo_url
        return this
    }

    unset(): this {
        this.fill(<any>{})
        return this
    }
}

<template>
    <div>
        <app-bar :title="definition.name"/>

        <v-layout>
            <v-progress-linear
                v-if="overlay"
                absolute
                indeterminate
            ></v-progress-linear>

            <div class="pt-8 pb-12 px-8">
                <div class="pb-10">
                    <v-btn
                        @click="edit"
                        class="sm mr-5"
                        color="primary"
                    >{{ t('general_action_edit') }}
                    </v-btn>
                    <v-btn
                        class="sm mr-5"
                        color="primary"
                        outlined
                        @click.prevent="suspend(definition)"
                    >
                        {{ definition.published ? t('general_action_suspend') : t('general_action_unsuspend') }}
                    </v-btn>
                    <confirm-modal
                        :title="t('general_title_confirm_action')"
                        @agree="del(definition)"
                    >
                        <template #default="{ on, attrs }">
                            <v-btn
                                class="sm"
                                color="orange"
                                outlined
                                v-on="on"
                                v-bind="attrs"
                            >{{ t('general_action_delete') }}</v-btn>
                        </template>
                    </confirm-modal>
                </div>
                <div class="float-left mr-10" style="width: 394px; max-width: 394px">
                    <div class="form-subtitle">{{ t('glossary_definition_label_content') }}:</div>
                    <div class="form-subtitle-description" v-html="definition.content"></div>
                </div>
                <div class="float-left" style="max-width: calc(100% - 434px)">
                    <card-unit :items="items()">
                        <template #title>{{ t('glossary_definition_label_id') }}: {{ definition.id }}</template>
                    </card-unit>
                </div>
            </div>
        </v-layout>
    </div>
</template>

<script lang="ts">
    import { computed, defineComponent, onMounted, reactive, toRefs } from '@vue/composition-api'
    import { useI18n } from 'vue-i18n-composable'
    import { useStore } from '@/store'
    import { useRouter } from '@/composition-api'
    import { useEnums } from '@/core/defaults'
    import { GlossaryDefinition } from '@/core/models/glossary-definition'
    import moment from 'moment/moment'
    import ConfirmModal from '@/components/ConfirmModal.vue'
    import CardUnit from '@/components/CardUnit.vue'

    export default defineComponent({
        components: {
            ConfirmModal,
            CardUnit
        },

        /**
         * Component constructor.
         *
         * @param props
         */
        setup(props) {
            const store = useStore()
            const router = useRouter()
            const enums = useEnums()
            const i18n = useI18n()
            const id = router.currentRoute.params['id']

            const state = reactive({
                overlay: true,
                currentTab: <number | null>null,
            })

            const capture = () => {
                store.dispatch('glossaryDefinition/get', { id: id, payload: { published: '', relations: 'section' } }).then(() => {
                    state.overlay = false
                })
            }

            const definitionModel = computed(() => <GlossaryDefinition>store.getters['glossaryDefinition/get'])

            const suspend = (definitionModel: GlossaryDefinition) => {
                state.overlay = true
                store.dispatch('glossaryDefinition/update', {
                    id: definitionModel.id,
                    payload: { published: ! definitionModel.published }
                }).then(() => {
                    store.dispatch('notify', { color: 'success', message: i18n.t('general_notice_data_updated') })
                    capture()
                })
            }

            const edit = () => router.push({ name: 'glossary-definitions.show', params: { id: <any>definitionModel.value.id } })

            const del = (definitionModel: GlossaryDefinition) => {
                state.overlay = true
                store.dispatch('glossaryDefinition/delete', { id: definitionModel.id }).then(() => {
                    router.push({ name: 'glossary-definitions' })
                })
            }

            const items = () => {
                let items = <any>[
                    { text: i18n.t('glossary_definition_label_section'), value: definitionModel.value.section.name },
                    { text: i18n.t('general_label_creation_date'), value: definitionModel.value.createdAt.format('DD/MM/YYYY') },
                    { text: i18n.t('glossary_definition_label_video'), value: definitionModel.value.video ? i18n.t('general_text_yes') : i18n.t('general_text_no') },

                ]
                if (definitionModel.value.video) {
                    items = items.concat([{
                        text: i18n.t('glossary_definition_label_video_link'),
                        value: definitionModel.value.video,
                    }])
                }
                items = items.concat([
                    { text: i18n.t('glossary_definition_label_published'), value: definitionModel.value.published ? i18n.t('general_text_yes') : i18n.t('general_text_no') },
                    { text: i18n.t('glossary_definition_label_sort_order'), value: definitionModel.value.sortOrder },
                ])
                return items
            }

            onMounted(() => {
                capture()
            })

            return {
                capture,
                definition: definitionModel.value,
                items,
                moment,
                edit,
                del,
                suspend,
                ...useI18n(),
                ...useEnums(),
                ...toRefs(state)
            }
        }
    })
</script>
